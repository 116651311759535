import { defineMessages } from 'react-intl';

const messages = defineMessages({
    brand: {
        id: 'radicalstart.brand',
        defaultMessage: 'RadicalStart',
        description: 'Brand name displayed in header',
    },
    about: {
        id: 'radicalstart.about',
        defaultMessage: 'About',
        description: 'About Nav link',
    },
    login: {
        id: 'radicalstart.login',
        defaultMessage: 'Login',
        description: 'Login Nav link',
    },
    signup: {
        id: 'radicalstart.signup',
        defaultMessage: 'Sign up',
        description: 'Sign up Nav link',
    },
    contact: {
        id: 'radicalstart.contact',
        defaultMessage: 'Contact',
        description: 'Contact Nav link',
    },
    bannerTitle: {
        id: 'radicalstart.bannerTitle',
        defaultMessage: 'React',
        description: 'Title in page header',
    },
    logo: {
        id: 'radicalstart.logo',
        defaultMessage: 'Logo',
        description: 'Logo'
    },
    siteSettings: {
        id: 'radicalstart.siteSettings',
        defaultMessage: 'Site Settings',
        description: 'Site Settings'
    },
    HomeSiteSettings: {
        id: 'radicalstart.HomeSiteSettings',
        defaultMessage: 'Homepage Settings',
        description: 'HomeSiteSettings'
    },
    driverRatings: {
        id: 'radicalstart.driverRatings',
        defaultMessage: 'Driver Ratings',
        description: 'Driver Ratings'
    },
    messageRequired: {
        id: 'radicalstart.messageRequired',
        defaultMessage: 'Message is required',
        description: 'Message is required'
    },
    messageLength: {
        id: 'radicalstart.messageLength',
        defaultMessage: 'Message must be 200 characters or less',
        description: 'Message must be 200 characters or less'
    },
    metaKeyword: {
        id: 'radicalstart.metaKeyword',
        defaultMessage: 'Keyword must be 255 characters or less',
        description: 'Keyword must be 255 characters or less'
    },
    metaTitle: {
        id: 'radicalstart.metaTitle',
        defaultMessage: 'Meta Title',
        description: 'Meta Title'
    },
    metaDescriptionText: {
        id: 'radicalstart.metaDescriptionText',
        defaultMessage: 'Meta Description',
        description: 'Meta Description'
    },
    metaDescription: {
        id: 'radicalstart.metaDescription',
        defaultMessage: 'Description must be 255 characters or less',
        description: 'Description must be 255 characters or less'
    },
    pageTitle: {
        id: 'radicalstart.pageTitle',
        defaultMessage: 'Page Title',
        description: 'Page Title'
    },
    pageUrl: {
        id: 'radicalstart.pageUrl',
        defaultMessage: 'Page URL',
        description: 'Page URL'
    },
    invalidUrl: {
        id: 'radicalstart.invalidUrl',
        defaultMessage: 'Invalid Page URL',
        description: 'Invalid Page URL'
    },
    footerCategory: {
        id: 'radicalstart.footerCategory',
        defaultMessage: 'Footer Category',
        description: 'Footer Category'
    },
    ratings: {
        id: 'radicalstart.ratings',
        defaultMessage: 'Ratings',
        description: 'Ratings'
    },
    reasonRequired: {
        id: 'radicalstart.reasonRequired',
        defaultMessage: 'Reason is required',
        description: 'Reason is required'
    },
    exceedLimit100: {
        id: 'radicalstart.exceedLimit100',
        defaultMessage: 'Please dont exceed 100 words',
        description: 'Please dont exceed 100 words'
    },
    exceedLimit: {
        id: 'radicalstart.exceedLimit',
        defaultMessage: 'Exceeding character limit',
        description: 'Exceeding character limit'
    },
    comments: {
        id: 'radicalstart.comments',
        defaultMessage: 'Comments',
        description: 'Comments'
    },
    cancellationReasons: {
        id: 'radicalstart.cancellationReasons',
        defaultMessage: 'Cancellation Reasons',
        description: 'Cancellation Reasons'
    },
    cancelReason: {
        id: 'radicalstart.cancelReason',
        defaultMessage: 'Cancel Reason',
        description: 'Cancel Reason'
    },
    cancelledBy: {
        id: 'radicalstart.cancelledBy',
        defaultMessage: 'Cancelled By',
        description: 'Cancelled By'
    },
    tripDate: {
        id: 'radicalstart.tripDate',
        defaultMessage: 'Trip Date',
        description: 'Trip Date'
    },
    changePassword: {
        id: 'radicalstart.changePassword',
        defaultMessage: 'Change Password',
        description: 'Change Password'
    },
    addCancelReason: {
        id: 'radicalstart.addCancelReason',
        defaultMessage: 'Add Cancel Reason',
        description: 'Add Cancel Reason'
    },
    editCancelReason: {
        id: 'radicalstart.editCancelReason',
        defaultMessage: 'Edit Cancel Reason',
        description: 'Edit Cancel Reason'
    },
    reason: {
        id: 'radicalstart.reason',
        defaultMessage: 'Reason',
        description: 'Reason'
    },
    manageNotifications: {
        id: 'radicalstart.manageNotifications',
        defaultMessage: 'Manage Notifications',
        description: 'Manage Notifications'
    },
    bannerDesc: {
        id: 'radicalstart.bannerDesc',
        defaultMessage: 'Complex web apps made easy',
        description: 'Description in header',
    },
    recommand: {
        id: 'radicalstart.recommand',
        defaultMessage: 'Recommand',
        description: 'recommand',
    },
    sliderContent: {
        id: 'radicalstart.sliderContent',
        defaultMessage: 'Luxury',
        description: 'Luxury',
    },
    heading: {
        id: 'radicalstart.heading',
        defaultMessage: 'Heading',
        description: 'heading',
    },
    dummyText: {
        id: 'radicalstart.dummyText',
        defaultMessage: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        description: 'dummyText',
    },
    rentallContent: {
        id: 'radicalstart.rentallContent',
        defaultMessage: 'RentALL',
        description: 'RentALL',
    },
    aboutFooter: {
        id: 'radicalstart.aboutFooter',
        defaultMessage: 'About Us',
        description: 'AboutFooter',
    },
    contactUs: {
        id: 'radicalstart.contactUs',
        defaultMessage: 'Contact Us',
        description: 'contact',
    },
    Trust: {
        id: 'radicalstart.Trust',
        defaultMessage: 'Trust & Safety',
        description: 'Trust',
    },
    Travel: {
        id: 'radicalstart.Travel',
        defaultMessage: 'Travel Credit',
        description: 'Trust',
    },
    Discover: {
        id: 'radicalstart.Discover',
        defaultMessage: 'Discover',
        description: 'Discover',
    },
    Hosting: {
        id: 'radicalstart.Hosting',
        defaultMessage: 'Hosting',
        description: 'Hosting',
    },
    Becomeahost: {
        id: 'radicalstart.Becomeahost',
        defaultMessage: 'Become a host',
        description: 'Becomeahost',
    },
    Terms: {
        id: 'radicalstart.Terms',
        defaultMessage: 'Terms & Privacy',
        description: 'Terms',
    },
    adminpanel: {
        id: 'radicalstart.adminpanel',
        defaultMessage: 'Admin Panel',
        description: 'admin panel',
    },
    dashboard: {
        id: 'radicalstart.dashboard',
        defaultMessage: 'Dashboard',
        description: 'dashboard',
    },
    settings: {
        id: 'radicalstart.settings',
        defaultMessage: 'Settings',
        description: 'settings',
    },
    users: {
        id: 'radicalstart.users',
        defaultMessage: 'Users',
        description: 'users',
    },
    formssidebar: {
        id: 'radicalstart.formssidebar',
        defaultMessage: 'Forms',
        description: 'formssidebar',
    },
    email: {
        id: 'radicalstart.emailaddress',
        defaultMessage: 'Email Address',
        description: 'emailaddress',
    },
    emailaddress: {
        id: 'radicalstart.emailaddress',
        defaultMessage: 'Email Address',
        description: 'emailaddress',
    },
    password: {
        id: 'radicalstart.password',
        defaultMessage: 'Password',
        description: 'password',
    },
    select: {
        id: 'radicalstart.select',
        defaultMessage: 'Select',
        description: 'select',
    },
    textarea: {
        id: 'radicalstart.textarea',
        defaultMessage: 'Text Area',
        description: 'textarea',
    },
    firstName: {
        id: 'radicalstart.firstName',
        defaultMessage: 'First Name',
        description: 'firstName',
    },
    lastName: {
        id: 'radicalstart.lastName',
        defaultMessage: 'Last Name',
        description: 'lastName',
    },
    phoneNumber: {
        id: 'radicalstart.phoneNumber',
        defaultMessage: 'Phone Number',
        description: 'phoneNumber',
    },
    profilePicture: {
        id: 'radicalstart.profilePicture',
        defaultMessage: 'Profile Picture',
        description: 'profilePicture',
    },
    licenceFrontImage: {
        id: 'radicalstart.licenceFrontImage',
        defaultMessage: 'Licence Front Image',
        description: 'licenceFrontImage'
    },
    licenceBackImage: {
        id: 'radicalstart.licenceBackImage',
        defaultMessage: 'Licence Back Image',
        description: 'licenceBackImage'
    },
    rcImage: {
        id: 'radicalstart.rcImage',
        defaultMessage: 'RC Book Image',
        description: 'rcImage'
    },
    insuranceImage: {
        id: 'radicalstart.insuranceImage',
        defaultMessage: 'Insurance Image',
        description: 'insuranceImage'
    },
    required: {
        id: 'radicalstart.required',
        defaultMessage: '*Required',
        description: 'required error message',
    },
    emailRequired: {
        id: 'radicalstart.emailRequired',
        defaultMessage: 'Email is required',
        description: 'Email is required',
    },
    emailInvalid: {
        id: 'radicalstart.emailInValid',
        defaultMessage: 'Invalid Email Address',
        description: 'Invalid Email Address',
    },
    emailNotExists: {
        id: 'radicalstart.emailNotExists',
        defaultMessage: 'No account exists for this email. Make sure it\'s typed in correctly, or “sign up” instead',
        description: 'Email Address is not exist',
    },
    passwordRequired: {
        id: 'radicalstart.passwordRequired',
        defaultMessage: 'Password is required',
        description: 'Password is required',
    },
    passwordInvalid: {
        id: 'radicalstart.passwordInValid',
        defaultMessage: 'Your password must be at least 8 characters. Please try again',
        description: 'Invalid Password',
    },
    passwordWrong: {
        id: 'radicalstart.passwordWrong',
        defaultMessage: 'Wrong Password. Please try again',
        description: 'Wrong Password',
    },
    somethingWentWrong: {
        id: 'radicalstart.somethingWentWrong',
        defaultMessage: 'Sorry, something went wrong. Please try again',
        description: 'Wrong went wrong',
    },
    loggedIn: {
        id: 'radicalstart.loggedIn',
        defaultMessage: 'You are already logged in!',
        description: 'You are already Logged-in',
    },
    submitButton: {
        id: 'radicalstart.submitButton',
        defaultMessage: 'Submit',
        description: 'Submit',
    },
    cancelButton: {
        id: 'radicalstart.cancelButton',
        defaultMessage: 'Cancel',
        description: 'Cancel',
    },
    table: {
        id: 'radicalstart.table',
        defaultMessage: 'Table',
        description: 'Table',
    },
    form: {
        id: 'radicalstart.form',
        defaultMessage: 'Form',
        description: 'form',
    },
    menuOne: {
        id: 'radicalstart.menuOne',
        defaultMessage: 'MenuOne',
        description: 'form',
    },
    menuTwo: {
        id: 'radicalstart.menuTwo',
        defaultMessage: 'MenuTwo',
        description: 'MenuTwo',
    },
    menuFour: {
        id: 'radicalstart.menuFour',
        defaultMessage: 'MenuFour',
        description: 'menuFour',
    },
    subMenuOne: {
        id: 'radicalstart.subMenuOne',
        defaultMessage: 'subMenuOne',
        description: 'subMenuOne',
    },
    subMenuTwo: {
        id: 'radicalstart.subMenuTwo',
        defaultMessage: 'subMenuTwo',
        description: 'subMenuTwo',
    },
    subMenuThree: {
        id: 'radicalstart.subMenuThree',
        defaultMessage: 'subMenuThree',
        description: 'subMenuThree',
    },
    siteAdmin: {
        id: 'radicalstart.siteAdmin',
        defaultMessage: 'Site Admin',
        description: 'siteAdmin',
    },
    ridersMenu: {
        id: 'radicalstart.ridersMenu',
        defaultMessage: 'Manage Riders',
        description: 'ridersMenu',
    },
    driverMenu: {
        id: 'radicalstart.driverMenu',
        defaultMessage: 'Manage Drivers',
        description: 'driverMenu',
    },
    vehiclesMenu: {
        id: 'radicalstart.vehiclesMenu',
        defaultMessage: 'Manage Vehicles',
        description: 'vehiclesMenu',
    },
    categoryMenu: {
        id: 'radicalstart.categoryMenu',
        defaultMessage: 'Manage Categories',
        description: 'categoryMenu',
    },
    bookingsMenu: {
        id: 'radicalstart.bookingsMenu',
        defaultMessage: 'Manage Bookings',
        description: 'bookingsMenu',
    },
    Wooberly: {
        id: 'radicalstart.Wooberly',
        defaultMessage: 'Wooberly',
        description: 'Wooberly',
    },
    city: {
        id: 'radicalstart.city',
        defaultMessage: 'Lorem Ipsum is simply dummy text',
        description: 'city',
    },
    categoryTitle: {
        id: 'radicalstart.categoryTitle',
        defaultMessage: 'Category Title',
        description: 'Category Title',
    },
    categoryContent: {
        id: 'radicalstart.categoryContent',
        defaultMessage: 'Category Content',
        description: 'Category Content',
    },
    viewCity: {
        id: 'radicalstart.viewCity',
        defaultMessage: 'View city',
        description: 'view City',
    },
    Safety: {
        id: 'radicalstart.Safety',
        defaultMessage: 'Safety',
        description: 'Safety',
    },
    safetyContent: {
        id: 'radicalstart.safetyContent',
        defaultMessage: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        description: 'Safety',
    },
    safetyButton: {
        id: 'radicalstart.safetyButton',
        defaultMessage: 'View all safety features',
        description: 'safetyButton',
    },
    signupDrive: {
        id: 'radicalstart.signupDrive',
        defaultMessage: 'Sign up to drive',
        description: 'Sign up to drive',
    },
    signupRide: {
        id: 'radicalstart.signupRide',
        defaultMessage: 'Sign up to ride',
        description: 'Sign up to ride',
    },
    profileId: {
        id: 'radicalstart.profileId',
        defaultMessage: 'Profile ID',
        description: 'Profile Id for user'
    },
    country: {
        id: 'radicalstart.country',
        defaultMessage: 'Country',
        description: 'Country Name'
    },
    createdAt: {
        id: 'radicalstart.createdAt',
        defaultMessage: 'Account Created on',
        description: 'Created date'
    },
    action: {
        id: 'radicalstart.action',
        defaultMessage: 'Action',
        description: 'Action'
    },
    vehicleType: {
        id: 'radicalstart.vehicleType',
        defaultMessage: 'Vehicle Type',
        description: 'Vehicle Type'
    },
    minBasePrice: {
        id: 'radicalstart.minBasePrice',
        defaultMessage: 'Minimum Base Price',
        description: 'Minimum base price amount'
    },
    riderCommission: {
        id: 'radicalstart.riderCommission',
        defaultMessage: 'Rider Commission(%)',
        description: 'Rider Commission Percentage'
    },
    driverCommission: {
        id: 'radicalstart.driverCommission',
        defaultMessage: 'Driver Commission(%)',
        description: 'Driver Commission Percentage'
    },
    vehicleName: {
        id: 'radicalstart.vehicleName',
        defaultMessage: 'Vehicle Name',
        description: 'Vehicle Name'
    },
    vehicleNumber: {
        id: 'radicalstart.vehicleNumber',
        defaultMessage: 'Vehicle Number',
        description: 'Vehicle Number'
    },
    status: {
        id: 'radicalstart.status',
        defaultMessage: 'Status',
        description: 'Status Active/Inactive'
    },
    editAction: {
        id: 'radicalstart.editAction',
        defaultMessage: 'Edit',
        description: 'Edit action name'
    },
    deleteAction: {
        id: 'radicalstart.deleteAction',
        defaultMessage: 'Delete',
        description: 'Delete action name'
    },
    vehicleOwnerName: {
        id: 'radicalstart.vehicleOwnerName',
        defaultMessage: 'Vehicle Owner Name',
        description: 'Vehicle Owner Name'
    },
    vehicleOwnerEmail: {
        id: 'radicalstart.vehicleOwnerEmail',
        defaultMessage: 'Vehicle Owner Email',
        description: 'Vehicle Owner Email'
    },
    minutePrice: {
        id: 'radicalstart.minutePrice',
        defaultMessage: 'Minute Price',
        description: 'Minute Price'
    },
    unitPrice: {
        id: 'radicalstart.unitPrice',
        defaultMessage: 'Unit Price',
        description: 'Unit Price'
    },
    riderFeeType: {
        id: 'radicalstart.riderFeeType',
        defaultMessage: 'Rider Fee Type',
        description: 'Rider Fee Type'
    },
    fixedFeeType: {
        id: 'radicalstart.fixed',
        defaultMessage: 'Fixed',
        description: 'Fixed Fee Type'
    },
    percentageFeeType: {
        id: 'radicalstart.percentageFeeType',
        defaultMessage: 'Percentage',
        description: 'Percentage Fee Type'
    },
    driverFeeType: {
        id: 'radicalstart.driverFeeType',
        defaultMessage: 'Driver Fee Type',
        description: 'Driver Fee Type'
    },
    totalFare: {
        id: 'radicalstart.totalFare',
        defaultMessage: 'Total Fare',
        description: 'Total Fare'
    },
    promoCodeId: {
        id: 'radicalstart.promoCodeId',
        defaultMessage: 'Promo Code',
        description: 'Promo Code'
    },
    isSpecialTrip: {
        id: 'radicalstart.isSpecialTrip',
        defaultMessage: 'Special Trip',
        description: 'Special Trip'
    },
    specialTripPrice: {
        id: 'radicalstart.specialTripPrice',
        defaultMessage: 'Rider Discount Amount',
        description: 'discount text'
    },
    riderPayableAmount: {
        id: 'radicalstart.riderPayableAmount',
        defaultMessage: 'Rider Payable Amount',
        description: 'Special Trip Total Fare'
    },
    tripStatus: {
        id: 'radicalstart.tripStatus',
        defaultMessage: 'Trip Status',
        description: 'Trip Status'
    },
    riderName: {
        id: 'radicalstart.riderName',
        defaultMessage: 'Rider Name',
        description: 'Rider Name'
    },
    driverName: {
        id: 'radicalstart.driverName',
        defaultMessage: 'Driver Name',
        description: 'Driver Name'
    },
    riderEmail: {
        id: 'radicalstart.riderEmail',
        defaultMessage: 'Rider Email',
        description: 'Rider Email'
    },
    driverEmail: {
        id: 'radicalstart.driverEmail',
        defaultMessage: 'Driver Email',
        description: 'Driver Email'
    },
    pickUpLocation: {
        id: 'radicalstart.pickupLocation',
        defaultMessage: 'PickUp Location',
        description: 'PickUp Location'
    },
    dropLocation: {
        id: 'radicalstart.dropLocation',
        defaultMessage: 'Drop Location',
        description: 'Drop Location'
    },
    bookingId: {
        id: 'radicalstart.bookingId',
        defaultMessage: 'Booking ID',
        description: 'Booking ID'
    },
    category: {
        id: 'radicalstart.category',
        defaultMessage: 'Category',
        description: 'Category'
    },
    vehicleNumber: {
        id: 'radicalstart.vehicleNumber',
        defaultMessage: 'Vehicle Number',
        description: 'Vehicle Number'
    },
    totalRideDistance: {
        id: 'radicalstart.totalRideDistance',
        defaultMessage: 'Total Rider Distance',
        description: 'Total Rider Distance'
    },
    totalDuration: {
        id: 'radicalstart.totalDuration',
        defaultMessage: 'Total Duration',
        description: 'Total Duration'
    },
    vehicleId: {
        id: 'radicalstart.vehicleId',
        defaultMessage: 'Vehicle ID',
        description: 'Vehicle ID'
    },
    categoryId: {
        id: 'radicalstart.categoryId',
        defaultMessage: 'Category ID',
        description: 'Category ID'
    },
    view: {
        id: 'radicalstart.view',
        defaultMessage: 'View',
        description: 'View Details Link'
    },
    details: {
        id: 'radicalstart.details',
        defaultMessage: 'Details',
        description: 'Details'
    },
    riderServiceFee: {
        id: 'radicalstart.riderServiceFee',
        defaultMessage: 'Rider Service Fee',
        description: 'Rider Service Fee'
    },
    driverServiceFee: {
        id: 'radicalstart.driverServiceFee',
        defaultMessage: 'Driver Service Fee',
        description: 'Driver Service Fee'
    },
    riderTotalFare: {
        id: 'radicalstart.riderTotalFare',
        defaultMessage: 'Rider Total Fare',
        description: 'Rider Total Fare'
    },
    driverTotalFare: {
        id: 'radicalstart.driverTotalFare',
        defaultMessage: 'Driver Total Fare',
        description: 'Driver Total Fare'
    },
    tripStart: {
        id: 'radicalstart.tripStart',
        defaultMessage: 'Trip Start',
        description: 'Trip Start'
    },
    tripEnd: {
        id: 'radicalstart.tripEnd',
        defaultMessage: 'Trip End',
        description: 'Trip End'
    },
    categoryName: {
        id: 'radicalstart.categoryName',
        defaultMessage: 'Category Name',
        description: 'Category Name'
    },
    minutes: {
        id: 'radicalstart.minutes',
        defaultMessage: 'minutes',
        description: 'minutes'
    },
    update: {
        id: 'radicalstart.update',
        defaultMessage: 'Update',
        description: 'Update'
    },
    userStatus: {
        id: 'radicalstart.userStatus',
        defaultMessage: 'User Status',
        description: 'User Status Active/Inactive'
    },
    active: {
        id: 'radicalstart.active',
        defaultMessage: 'Active',
        description: 'Active Status'
    },
    inactive: {
        id: 'radicalstart.inactive',
        defaultMessage: 'Inactive',
        description: 'Inactive Status'
    },
    pending: {
        id: 'radicalstart.pending',
        defaultMessage: 'Pending',
        description: 'Pending Status'
    },
    pendingPoints: {
        id: 'radicalstart.pendingPoints',
        defaultMessage: 'Pending Points',
        description: 'Pending Points'
    },
    acquiredPoints: {
        id: 'radicalstart.acquiredPoints',
        defaultMessage: 'Acquired Points Available',
        description: 'Acquired Points'
    },
    banStatus: {
        id: 'radicalstart.banStatus',
        defaultMessage: 'Ban Status',
        description: 'Ban Status Permit/Ban'
    },
    permit: {
        id: 'radicalstart.permit',
        defaultMessage: 'Permit',
        description: 'Permit Status'
    },
    ban: {
        id: 'radicalstart.ban',
        defaultMessage: 'Ban',
        description: 'Ban Status'
    },
    phoneDialCode: {
        id: 'radicalstart.phoneDialCode',
        defaultMessage: 'Country Code',
        description: 'Phone Dial Code'
    },
    dropzoneUpload: {
        id: "rentall.dropzoneUpload",
        defaultMessage: "Upload a file from your computer",
        description: "Dropzone upload info"
    },
    dropzoneUploadError: {
        id: "rentall.dropzoneUploadError",
        defaultMessage: "Remove the existing image and try upload again",
        description: "Dropzone upload error message"
    },
    riderFeeValue: {
        id: 'radicalstart.riderFeeValue',
        defaultMessage: 'Rider Commission',
        description: 'Rider Fee Value'
    },
    driverFeeValue: {
        id: 'radicalstart.driverFeeValue',
        defaultMessage: 'Driver Commission',
        description: 'Driver Fee Value'
    },
    addCategory: {
        id: 'radicalstart.addCategory',
        defaultMessage: 'Add Category',
        description: 'Add category form header'
    },
    editRider: {
        id: 'radicalstart.editRider',
        defaultMessage: 'Edit Rider',
        description: 'Edit rider form header'
    },
    editDriver: {
        id: 'radicalstart.editDriver',
        defaultMessage: 'Edit Driver',
        description: 'Edit driver form header'
    },
    editCategory: {
        id: 'radicalstart.editCategory',
        defaultMessage: 'Edit Category',
        description: 'Edit category form header'
    },
    editVehicle: {
        id: 'radicalstart.editVehicle',
        defaultMessage: 'Edit Vehicle',
        description: 'Edit vehicle form header'
    },
    goBack: {
        id: 'radicalstart.goBack',
        defaultMessage: 'Go Back',
        description: 'Go Back Button Text'
    },
    capacity: {
        id: 'radicalstart.capacity',
        defaultMessage: 'Capacity',
        description: 'Capacity'
    },
    floatError: {
        id: 'radicalstart.floatError',
        defaultMessage: 'It should be a number value',
        description: 'Numeric Error Response'
    },
    numericValue: {
        id: 'radicalstart.numericValue',
        defaultMessage: 'Numeric value required',
        description: 'Numeric value required'
    },
    validUrl: {
        id: 'radicalstart.validUrl',
        defaultMessage: 'Valid URL required',
        description: 'Valid URL required'
    },
    intError: {
        id: 'radicalstart.intError',
        defaultMessage: 'It should be a decimal value',
        description: 'Integer Error Response'
    },
    percentError: {
        id: 'radicalstart.percentError',
        defaultMessage: 'Choose percentage value between 1 to 99',
        description: 'Choose percentage value between 1 to 99'
    },
    phoneError: {
        id: 'radicalstart.numberError',
        defaultMessage: 'Invalid Phone Number',
        description: 'Invalid Phone Number'
    },
    countryCodeError: {
        id: 'radicalstart.countryCodeError',
        defaultMessage: 'Invalid Country Code',
        description: 'Invalid Country Code'
    },
    vehicleNumberError: {
        id: 'radicalstart.vehicleNumberError',
        defaultMessage: 'Invalid Vehicle Number',
        description: 'Invalid Vehicle Number'
    },
    availableSeats: {
        id: 'radicalstart.availableSeats',
        defaultMessage: 'Available Seats',
        description: 'Available Seats'
    },
    currency: {
        id: 'radicalstart.currency',
        defaultMessage: 'Currency',
        description: 'Currency Type'
    },
    invalidVehicleName: {
        id: 'radicalstart.invalidVehicleName',
        defaultMessage: 'Invalid Vehicle Name',
        description: 'Invalid Vehicle Name'
    },
    invalidVehicleNumber: {
        id: 'radicalstart.invalidVehicleNumber',
        defaultMessage: 'Invalid Vehicle Number',
        description: 'Invalid Vehicle Number'
    },
    vehicleNameError3: {
        id: 'radicalstart.vehicleNameError3',
        defaultMessage: 'Vehicle Name must be minimum 3 characters and maximum 15 characters',
        description: 'New Password must be minimum 8 characters'
    },
    manageDashboard: {
        id: 'radicalstart.manageDashboard',
        defaultMessage: 'Dashboard',
        description: 'Manage Dashboard'
    },
    chooseFile: {
        id: 'radicalstart.chooseFile',
        defaultMessage: 'Choose File',
        description: ''
    },
    categoryIcon: {
        id: 'radicalstart.categoryIcon',
        defaultMessage: 'Category Icon',
        description: ''
    },
    categoryMapMarker: {
        id: 'radicalstart.catego\ryMapMarker',
        defaultMessage: 'Category Map Marker',
        description: ''
    },
    maximumUploadSizeLabel: {
        id: 'radicalstart.maximumUploadSizeLabel',
        defaultMessage: 'Maximum upload size',
        description: ''
    },
    followUs: {
        id: 'radicalstart.followUs',
        defaultMessage: 'Follow  Us',
        description: 'Follow Us',
    },
    copyRights: {
        id: 'radicalstart.copyRights',
        defaultMessage: 'Wooberly 2020. All Rights Reserved',
        description: 'Copy Rights',
    },
    useFullLink: {
        id: 'radicalstart.useFullLink',
        defaultMessage: 'Usefull Links',
        description: 'Usefull Links',
    },
    startRideing: {
        id: 'radicalstart.startRideing',
        defaultMessage: 'Start Riding',
        description: 'Start Riding',
    },
    startDriving: {
        id: 'radicalstart.startDriving',
        defaultMessage: 'Start Driving',
        description: 'Start Driving',
    },
    privacyPolicies: {
        id: 'radicalstart.privacyPolicies',
        defaultMessage: 'Privacy Policy',
        description: 'Privacy Policy',
    },
    aboutFooterDesc: {
        id: 'radicalstart.aboutFooterDesc',
        defaultMessage: 'An cum maiorum repudiandae, cu eam dolore bonorum probatus. Et legere sanctus cum, quot nostrud postulant ex mei. Ad ullum audire admodum eos. Ne eam quod habeo aeque, ad decoreos.',
        description: 'aboutFooterDesc',
    },
    downloadAppText: {
        id: 'radicalstart.downloadAppText',
        defaultMessage: 'Download The Rider App',
        description: 'DownloadAppText',
    },
    downloadAppTextDriver: {
        id: 'radicalstart.downloadAppTextDriver',
        defaultMessage: 'Download The Driver App',
        description: 'DownloadAppText',
    },
    downloadAppDesc: {
        id: 'radicalstart.downloadAppDesc',
        defaultMessage: 'Delectus scaevola elaboraret vel ad, vis no noster vocent prodesset, nec ei omittantur dissentiet. Nobis postea ei est.',
        description: 'DownloadAppTextDesc',
    },
    downloadAppDriverDesc: {
        id: 'radicalstart.downloadAppDriverDesc',
        defaultMessage: 'Delectus scaevola elaboraret vel ad, vis no noster vocent prodesset, nec ei omittantur dissentiet. Nobis postea ei est.',
        description: 'DownloadAppTextDesc',
    },
    sliderTitle: {
        id: 'radicalstart.sliderTitle',
        defaultMessage: 'Lorem Ipsum',
        description: 'sliderTitle',
    },
    sliderTitleDesc: {
        id: 'radicalstart.sliderTitleDesc',
        defaultMessage: 'Percipit repudiandae an eum, enim case eos no. Percipit tractatos pertinacia cum id, ad eos facete malorum recusabo, vis insolens perpetua definitionem ex.',
        description: 'sliderTitleDesc',
    },
    rider: {
        id: 'radicalstart.rider',
        defaultMessage: 'Passager',
        description: 'Passager',
    },
    driver: {
        id: 'radicalstart.driver',
        defaultMessage: 'Chauffeur',
        description: 'Chauffeur',
    },
    homeContent: {
        id: 'radicalstart.homeContent',
        defaultMessage: 'Start Riding With Wooberly',
        description: 'Start Riding With Wooberly',
    },
    gridTitleOne: {
        id: 'radicalstart.gridTitle',
        defaultMessage: 'Lorem Ipsum',
        description: 'GridTitle',
    },
    gridTitleTwo: {
        id: 'radicalstart.gridTitleTwo',
        defaultMessage: 'Lorem Ipsum',
        description: 'GridTitle',
    },
    gridTitleThree: {
        id: 'radicalstart.gridTitleThree',
        defaultMessage: 'Lorem Ipsum',
        description: 'GridTitle',
    },
    gridTitleFour: {
        id: 'radicalstart.gridTitleFour',
        defaultMessage: 'Lorem Ipsum',
        description: 'GridTitle',
    },
    gridTitleFive: {
        id: 'radicalstart.gridTitleFive',
        defaultMessage: 'Lorem Ipsum',
        description: 'GridTitle',
    },
    gridTitleSix: {
        id: 'radicalstart.gridTitleSix',
        defaultMessage: 'Lorem Ipsum',
        description: 'GridTitle',
    },
    gridDescOne: {
        id: 'radicalstart.gridDescOne',
        defaultMessage: 'Id per gloriatur tincidunt. Vim odio unum atomorum at. Ut essent dicunt dolorum mei.',
        description: 'Grid Description',
    },
    gridDescTwo: {
        id: 'radicalstart.gridDescTwo',
        defaultMessage: 'Id per gloriatur tincidunt. Vim odio unum atomorum at. Ut essent dicunt dolorum mei.',
        description: 'Grid Description',
    },
    gridDescThree: {
        id: 'radicalstart.gridDescThree',
        defaultMessage: 'Id per gloriatur tincidunt. Vim odio unum atomorum at. Ut essent dicunt dolorum mei.',
        description: 'Grid Description',
    },
    gridDescFour: {
        id: 'radicalstart.gridDescFour',
        defaultMessage: 'Id per gloriatur tincidunt. Vim odio unum atomorum at. Ut essent dicunt dolorum mei.',
        description: 'Grid Description',
    },
    gridDescFive: {
        id: 'radicalstart.gridDescFive',
        defaultMessage: 'Id per gloriatur tincidunt. Vim odio unum atomorum at. Ut essent dicunt dolorum mei.',
        description: 'Grid Description',
    },
    gridDescSix: {
        id: 'radicalstart.gridDescSix',
        defaultMessage: 'Id per gloriatur tincidunt. Vim odio unum atomorum at. Ut essent dicunt dolorum mei.',
        description: 'Grid Description',
    },
    drivers: {
        id: 'radicalstart.drivers',
        defaultMessage: 'Drivers',
        description: 'drivers',
    },
    today: {
        id: 'radicalstart.today',
        defaultMessage: 'Today',
        description: 'today',
    },
    weekly: {
        id: 'radicalstart.weekly',
        defaultMessage: 'This Week',
        description: 'weekly',
    },
    montly: {
        id: 'radicalstart.montly',
        defaultMessage: 'This Month',
        description: 'montly',
    },
    riders: {
        id: 'radicalstart.riders',
        defaultMessage: 'Riders',
        description: 'Riders',
    },
    bookings: {
        id: 'radicalstart.bookings',
        defaultMessage: 'Bookings',
        description: 'bookings',
    },

    logout: {
        id: 'radicalstart.logout',
        defaultMessage: 'Logout',
        description: 'logout option',
    },

    goToMainSite: {
        id: 'radicalstart.goToMainSite',
        defaultMessage: 'Go to main site',
        description: 'goToMainSite option',
    },

    managePromoCode: {
        id: 'radicalstart.managePromoCode',
        defaultMessage: 'Manage Promo Code',
        description: 'managePromoCode sidemenu',
    },
    addPromoCode: {
        id: 'radicalstart.addPromoCode',
        defaultMessage: 'Add Promo Code',
        description: 'Add PromoCode',
    },
    editPromoCode: {
        id: 'radicalstart.editPromoCode',
        defaultMessage: 'Edit Promo Code',
        description: 'Edit PromoCode',
    },
    addPage: {
        id: 'radicalstart.addPage',
        defaultMessage: "Add Page",
        description: "Add Page"
    },
    id: {
        id: 'radicalstart.id',
        defaultMessage: 'ID',
        description: 'ID text',
    },
    title: {
        id: 'radicalstart.title',
        defaultMessage: 'Title',
        description: 'text',
    },
    description: {
        id: 'radicalstart.description',
        defaultMessage: 'Description',
        description: 'text',
    },
    code: {
        id: 'radicalstart.code',
        defaultMessage: 'Code',
        description: 'text',
    },
    discount: {
        id: 'radicalstart.discount',
        defaultMessage: 'Discount',
        description: 'text',
    },
    promoType: {
        id: 'radicalstart.promoType',
        defaultMessage: 'Type',
        description: 'text',
    },
    expireDate: {
        id: 'radicalstart.expireDate',
        defaultMessage: 'Expiry Date',
        description: 'text',
    },
    percentage: {
        id: 'radicalstart.percentage',
        defaultMessage: 'Percentage',
        description: 'text',
    },
    fixedAmount: {
        id: 'radicalstart.fixedAmount',
        defaultMessage: 'Fixed Amount',
        description: 'text',
    },
    usedPromoCode: {
        id: 'radicalstart.usedPromoCode',
        defaultMessage: 'Used Promo Code',
        description: 'text',
    },
    manageCompletedTrips: {
        id: 'radicalstart.manageCompletedTrips',
        defaultMessage: 'Completed Bookings',
        description: 'CompletedTrips sidemenu',
    },
    manageCancelledTrips: {
        id: 'radicalstart.manageCancelledTrips',
        defaultMessage: 'Cancelled Bookings',
        description: 'manageCancelledTrips sidemenu',
    },
    manageCancelReason: {
        id: 'radicalstart.manageCancelReason',
        defaultMessage: 'Cancel Reason',
        description: 'Cancel Reason',
    },
    manageCurrency: {
        id: 'radicalstart.manageCurrency',
        defaultMessage: 'Manage Currency',
        description: 'manageCurrency sidemenu',
    },
    symbol: {
        id: 'radicalstart.symbol',
        defaultMessage: 'Symbol',
        description: 'currencyManagement value',
    },
    baseCurrency: {
        id: 'radicalstart.BaseCurrency',
        defaultMessage: 'Base Currency',
        description: 'currencyManagement value',
    },
    enableDisable: {
        id: 'radicalstart.enableDisable',
        defaultMessage: 'Set Enable / Disable',
        description: 'currencyManagement value',
    },
    setBaseCurrency: {
        id: 'radicalstart.setBaseCurrency',
        defaultMessage: 'Set Base Currency',
        description: 'currencyManagement value',
    },
    allowedPaymentCurrency: {
        id: 'radicalstart.allowedPaymentCurrency',
        defaultMessage: 'Allowed Payment Currency',
        description: 'currencyManagement value',
    },
    reviewsBy: {
        id: 'radicalstart.reviewsBy',
        defaultMessage: 'Reviews By',
        description: 'reviewsBy'
    },
    noResult: {
        id: 'radicalstart.noResult',
        defaultMessage: 'No results found',
        description: 'noResult'
    },
    addLocation: {
        id: 'radicalstart.addLocation',
        defaultMessage: 'Add Location',
        description: 'addLocation'
    },
    tollFee: {
        id: 'radicalstart.tollFee',
        defaultMessage: 'Toll Fee',
        description: 'tollFee'
    },
    location: {
        id: 'radicalstart.location',
        defaultMessage: 'Manage Location',
        description: 'location'
    },
    edit: {
        id: 'radicalstart.edit',
        defaultMessage: 'Edit',
        description: 'edit'
    },
    managePayout: {
        id: 'radicalstart.managePayout',
        defaultMessage: 'Manage Payout',
        description: 'managePayout'
    },
    amount: {
        id: 'radicalstart.Amount',
        defaultMessage: 'Amount',
        description: 'Amount'
    },
    payoutStatus: {
        id: 'radicalstart.payoutStatus',
        defaultMessage: 'Payout Status',
        description: 'Payout Status'
    },
    holdPayout: {
        id: 'radicalstart.holdPayout',
        defaultMessage: 'Hold Payout',
        description: 'Hold Payout'
    },
    paymentType: {
        id: 'radicalstart.paymentType',
        defaultMessage: 'Payment Type',
        description: 'Payment Type'
    },
    yes: {
        id: 'radicalstart.yes',
        defaultMessage: 'Yes',
        description: 'Yes'
    },
    no: {
        id: 'radicalstart.no',
        defaultMessage: 'No',
        description: 'no'
    },
    homeSectionSettings: {
        id: 'radicalstart.homeSectionSettings',
        defaultMessage: 'Banner',
        description: 'Banner Settings'
    },
    homepageBanner: {
        id: 'radicalstart.homepageBanner',
        defaultMessage: 'Homepage Banner',
        description: 'Homepage Banner Settings'
    },
    aboutSectionSettings: {
        id: 'radicalstart.aboutSectionSettings',
        defaultMessage: 'Top Features',
        description: 'Top Features Settings'
    },
    citySectionSettings: {
        id: 'radicalstart.citySectionSettings',
        defaultMessage: 'Category',
        description: 'Category Settings'
    },
    safetySectionSettings: {
        id: 'radicalstart.safetySectionSettings',
        defaultMessage: 'Rider App',
        description: 'Rider Apps Settings'
    },
    signupSectionSettings: {
        id: 'radicalstart.signupSectionSettings',
        defaultMessage: 'Driver App',
        description: 'Driver Apps Settings'
    },
    footerSectionSettings: {
        id: 'radicalstart.footerSectionSettings',
        defaultMessage: 'Footer',
        description: 'Footer Settings'
    },
    manageFailedPayout: {
        id: 'radicalstart.manageFailedPayout',
        defaultMessage: 'Failed Payout',
        description: 'Failed Payout'
    },
    title1: {
        id: 'radicalstart.title1',
        defaultMessage: 'Title 1',
        description: 'Title 1'
    },
    title2: {
        id: 'radicalstart.title2',
        defaultMessage: 'Title 2',
        description: 'Title 2'
    },
    title3: {
        id: 'radicalstart.title3',
        defaultMessage: 'Title 3',
        description: 'Title 3'
    },
    title4: {
        id: 'radicalstart.title4',
        defaultMessage: 'Title 4',
        description: 'Title 4'
    },
    title5: {
        id: 'radicalstart.title5',
        defaultMessage: 'Title 5',
        description: 'Title 5'
    },
    title6: {
        id: 'radicalstart.title6',
        defaultMessage: 'Title 6',
        description: 'Title 6'
    },
    content1: {
        id: 'radicalstart.content1',
        defaultMessage: 'Content 1',
        description: 'Content 1'
    },
    content2: {
        id: 'radicalstart.content2',
        defaultMessage: 'Content 2',
        description: 'Content 2'
    },
    content3: {
        id: 'radicalstart.content3',
        defaultMessage: 'Content 3',
        description: 'Content 3'
    },
    content4: {
        id: 'radicalstart.content4',
        defaultMessage: 'Content 4',
        description: 'Content 4'
    },
    content5: {
        id: 'radicalstart.content5',
        defaultMessage: 'Content 5',
        description: 'Content 5'
    },
    content6: {
        id: 'radicalstart.content6',
        defaultMessage: 'Content 6',
        description: 'Content 6'
    },
    button: {
        id: 'radicalstart.button',
        defaultMessage: 'Button',
        description: 'Button'
    },
    aboutImage1: {
        id: 'radicalstart.aboutImage1',
        defaultMessage: 'Feature Banner #1',
        description: 'Feature Banner 1'
    },
    aboutImage2: {
        id: 'radicalstart.aboutImage2',
        defaultMessage: 'Feature Banner #2',
        description: 'Feature Banner 2'
    },
    homeImage1: {
        id: 'radicalstart.homeImage1',
        defaultMessage: 'Banner Image #1',
        description: 'Banner Image 1'
    },
    homeImage2: {
        id: 'radicalstart.homeImage2',
        defaultMessage: 'Banner Image #2',
        description: 'Banner Image 2'
    },
    homeImage3: {
        id: 'radicalstart.homeImage3',
        defaultMessage: 'Banner Image #3',
        description: 'Banner Image 3'
    },
    homeImage4: {
        id: 'radicalstart.homeImage4',
        defaultMessage: 'Banner Image #4',
        description: 'Banner Image 4'
    },
    homeImage5: {
        id: 'radicalstart.homeImage5',
        defaultMessage: 'Banner Image #5',
        description: 'Banner Image 5'
    },
    homeImage6: {
        id: 'radicalstart.homeImage6',
        defaultMessage: 'Banner Image #6',
        description: 'Banner Image 6'
    },
    homeImage7: {
        id: 'radicalstart.homeImage7',
        defaultMessage: 'Banner Image Tablet',
        description: 'Banner Image Tablet'
    },
    homeImage8: {
        id: 'radicalstart.homeImage8',
        defaultMessage: 'Banner Image Mobile',
        description: 'Banner Image Mobile'
    },
    homeTitle: {
        id: 'radicalstart.homeTitle',
        defaultMessage: 'Banner Title',
        description: 'Banner Title'
    },
    homeContent1: {
        id: 'radicalstart.homeContent1',
        defaultMessage: 'Banner Content',
        description: 'Banner Content'
    },
    safetyImage1: {
        id: 'radicalstart.safetyImage1',
        defaultMessage: 'Playstore Logo',
        description: 'Playstore Logo'
    },
    safetyImage2: {
        id: 'radicalstart.safetyImage2',
        defaultMessage: 'Appstore Logo',
        description: 'Appstore Logo'
    },
    safetyImage3: {
        id: 'radicalstart.safetyImage3',
        defaultMessage: 'Rider Banner',
        description: 'Rider Banner'
    },
    signupImage1: {
        id: 'radicalstart.signupImage1',
        defaultMessage: 'Playstore Logo',
        description: 'Playstore Logo'
    },
    signupImage2: {
        id: 'radicalstart.signupImage2',
        defaultMessage: 'Appstore Logo',
        description: 'Appstore Logo'
    },
    signupImage3: {
        id: 'radicalstart.signupImage3',
        defaultMessage: 'Driver Banner',
        description: 'Driver Banner'
    },
    footerLogo1: {
        id: 'radicalstart.footerLogo1',
        defaultMessage: 'Footer Logo #1',
        description: 'Footer Logo 1'
    },
    footerLogo2: {
        id: 'radicalstart.footerLogo2',
        defaultMessage: 'Footer Logo #2',
        description: 'Footer Logo 2'
    },
    footerLogo3: {
        id: 'radicalstart.footerLogo3',
        defaultMessage: 'Footer Logo #3',
        description: 'Footer Logo 3'
    },
    footerLogo4: {
        id: 'radicalstart.footerLogo4',
        defaultMessage: 'Footer Logo #4',
        description: 'Footer Logo 4'
    },
    tipsAmount: {
        id: 'radicalstart.tipsAmount',
        defaultMessage: 'Tips Amount',
        description: 'Tips Amount'
    },
    earnings: {
        id: 'radicalstart.earnings',
        defaultMessage: 'Your Earnings',
        description: 'Earnings'
    },
    overAll: {
        id: 'radicalstart.overAll',
        defaultMessage: 'Overall',
        description: 'Overall'
    },
    approve: {
        id: 'radicalstart.approve',
        defaultMessage: 'Approve',
        description: 'Approve'
    },
    decline: {
        id: 'radicalstart.decline',
        defaultMessage: 'Decline',
        description: 'Decline'
    },
    online: {
        id: 'radicalstart.online',
        defaultMessage: 'Online',
        description: 'online'
    },
    offline: {
        id: 'radicalstart.offline',
        defaultMessage: 'Offline',
        description: 'offline'
    },
    eligibleForATrip: {
        id: 'radicalstart.eligibleForATrip',
        defaultMessage: 'Eligible for a Trip',
        description: 'text'
    },
    notEligibleForATrip: {
        id: 'radicalstart.notEligibleForATrip',
        defaultMessage: 'Not eligible for a Trip',
        description: 'text'
    },
    preview: {
        id: 'radicalstart.preview',
        defaultMessage: 'Preview',
        description: 'Preview'
    },
    pageName: {
        id: 'radicalstart.pageName',
        defaultMessage: 'Page Name',
        description: 'Page Name'
    },
    staticpageManagement: {
        id: 'radicalstart.staticpageManagement',
        defaultMessage: 'Manage Static Content',
        description: 'Manage Static Content'
    },
    contentpageManagement: {
        id: 'radicalstart.contentpageManagement',
        defaultMessage: 'Content Management',
        description: 'Content Management'
    },
    editPageDetails: {
        id: 'radicalstart.editPageDetails',
        defaultMessage: 'Edit Page Details',
        description: 'Edit Page Details'
    },
    pageBanner: {
        id: 'radicalstart.pageBanner',
        defaultMessage: 'Page Banner',
        description: 'Page Banner'
    },
    pageImageBanner: {
        id: 'radicalstart.pageImageBanner',
        defaultMessage: 'Page Image Banner',
        description: 'Page Image Banner'
    },
    manageFare: {
        id: 'radicalstart.manageFare',
        defaultMessage: 'Manage Fare',
        description: 'Admin sidemenu'
    },
    manageReferralPoints: {
        id: 'radicalstart.manageReferralPoints',
        defaultMessage: 'Manage Referral Points',
        description: 'Admin sidemenu'
    },
    driverGodFather: {
        id: 'radicalstart.driverGodFather',
        defaultMessage: 'God Father',
        description: 'Manage referral points'
    },
    riderGodFather: {
        id: 'radicalstart.riderGodFather',
        defaultMessage: 'God Father',
        description: 'Manage referral points'
    },
    driverGodSon: {
        id: 'radicalstart.driverGodSon',
        defaultMessage: 'God Son',
        description: 'Manage referral points'
    },
    riderGodSon: {
        id: 'radicalstart.riderGodSon',
        defaultMessage: 'God Son',
        description: 'Manage referral points'
    },
    managePoints: {
        id: 'radicalstart.managePoints',
        defaultMessage: 'Manage Points',
        description: 'Admin sidemenu'
    },
    noOfTrips: {
        id: 'radicalstart.noOfTrips',
        defaultMessage: 'No. of Trips',
        description: 'Manage points'
    },
    driverLabel: {
        id: 'radicalstart.driverLabel',
        defaultMessage: 'Chauffeur',
        description: 'Manage points'
    },
    riderLabel: {
        id: 'radicalstart.riderLabel',
        defaultMessage: 'Passager',
        description: 'Manage points'
    },
    noOfPoints: {
        id: 'radicalstart.noOfPoints',
        defaultMessage: 'Points',
        description: 'Manage points'
    },
    totalTripAmount: {
        id: 'radicalstart.totalTripAmount',
        defaultMessage: 'Value of trips amount',
        description: 'Manage points'
    },
    reviews: {
        id: 'radicalstart.reviews',
        defaultMessage: 'Reviews',
        description: 'Manage points'
    },
    vehicleCategory: {
        id: 'radicalstart.vehicleCategory',
        defaultMessage: 'Vehicle Category',
        description: 'Manage points'
    },
    selectCategory: {
        id: 'radicalstart.selectCategory',
        defaultMessage: 'Select Category',
        description: 'Manage points'
    },
    numbersOnly: {
        id: 'radicalstart.numbersOnly',
        defaultMessage: 'Only numbers (eg: 25) are allowed',
        description: 'Manage points'
    },
    locationName: {
        id: 'radicalstart.locationName',
        defaultMessage: 'Location',
        description: 'Table header'
    },
    addFare: {
        id: 'radicalstart.addFare',
        defaultMessage: 'Add Fare',
        description: 'Button '
    },
    editFare: {
        id: 'radicalstart.adeditFaredFare',
        defaultMessage: 'Edit Fare',
        description: 'Button '
    },
    vechicleName: {
        id: 'radicalstart.vechicleName',
        defaultMessage: 'Vehicle',
        description: 'Vehicle'
    },
    paid: {
        id: 'radicalstart.paid',
        defaultMessage: 'Paid',
        description: 'Paid'
    },
    unpaid: {
        id: 'radicalstart.unpaid',
        defaultMessage: 'Unpaid',
        description: 'Unpaid'
    },
    payoutAmount: {
        id: 'radicalstart.payoutAmount',
        defaultMessage: 'Payout Amount',
        description: 'Payout Amount'
    },
    searchLocation: {
        id: 'radicalstart.searchLocation',
        defaultMessage: 'Search Location',
        description: 'Auto-Complete input'
    },
    removeLocation: {
        id: 'radicalstart.removeLocation',
        defaultMessage: 'Remove Location',
        description: 'Polygon remove'
    },
    walletBalance: {
        id: 'radicalstart.walletBalance',
        defaultMessage: 'Wallet Balance',
        description: 'Wallet Balance'
    },
    paymentMethod: {
        id: 'radicalstart.paymentMethod',
        defaultMessage: 'Payment Method',
        description: 'Payment Method'
    },
    card: {
        id: 'radicalstart.card',
        defaultMessage: 'Card',
        description: 'Card'
    },
    cash: {
        id: 'radicalstart.cash',
        defaultMessage: 'Cash',
        description: 'Cash'
    },
    wallet: {
        id: 'radicalstart.wallet',
        defaultMessage: 'Wallet',
        description: 'Wallet'
    },
    HomeLabel: {
        id: 'radicalstart.HomeLabel',
        defaultMessage: 'Accueil',
        description: 'Accueil'
    },
    categoryRequired: {
        id: 'radicalstart.categoryRequired',
        defaultMessage: 'Category is required',
        description: 'Category is required'
    },
    currencyRequired: {
        id: 'radicalstart.currencyRequired',
        defaultMessage: 'Currency is required',
        description: 'Currency is required'
    },
    driverGodSonLabel: {
        id: 'radicalstart.driverGodSonLabel',
        defaultMessage: 'Driver GodSon',
        description: 'Driver GodSon'
    },
    riderGodSonLabel: {
        id: 'radicalstart.driverGodFatherLabel',
        defaultMessage: 'Rider GodSon',
        description: 'Driver GodSon'
    },
    godfather: {
        id: 'radicalstart.godfather',
        defaultMessage: 'Godfather (Rider or Driver)',
        description: 'Godfather (Rider or Driver)'
    },
    manageSharePoints: {
        id: 'radicalstart.manageSharePoints',
        defaultMessage: 'Manage Share',
        description: 'Admin sidemenu'
    },
    pricePerPoints: {
        id: 'radicalstart.pricePerPoints',
        defaultMessage: 'Price per points',
        description: 'Admin sidemenu'
    },
    pointsPerShare: {
        id: 'radicalstart.pointsPerShare',
        defaultMessage: 'Points per share',
        description: 'Admin sidemenu'
    },
    additionalVehicletype: {
        id: 'radicalstart.additionalVehicletype',
        defaultMessage: 'Additional Vehicle type',
        description: 'Edit Vehicles Admin Page'
    },
    cookiesDisclaimer: {
        id: 'rentall.cookiesDisclaimer',
        defaultMessage: ': Ce site web stocke les cookies sur votre ordinateur. Ces cookies sont utilisés pour collecter des informations sur la manière dont vous interagissez avec notre site web. Nous utilisons ces informations afin d’améliorer et de personnaliser votre expérience de navigation. Pour en savoir plus,',
        description: 'CookiesDisclaimer'
    },
    cookiesDisclaimerLink: {
        id: 'radicalstart.cookiesDisclaimerLink',
        defaultMessage: 'consulter notre politique de confidentialité.',
        description: 'CookiesDisclaimerLink'
    },
    manageSharePointsLabel1: {
        id: 'radicalstart.manageSharePointsLabel1',
        defaultMessage: 'P1',
        description: 'P1'
    },
    manageSharePointsLabel2: {
        id: 'radicalstart.manageSharePointsLabel2',
        defaultMessage: 'P2',
        description: 'P2'
    },
    manageSharePointsLabel3: {
        id: 'radicalstart.manageSharePointsLabel3',
        defaultMessage: 'Initial number of shares (INIAC)',
        description: 'Initial number of shares (INIAC)'
    },
    manageSharePointsLabel4: {
        id: 'radicalstart.manageSharePointsLabel4',
        defaultMessage: 'Total number of shares (DISPAC)',
        description: 'Total number of shares (DISPAC)'
    },
    manageSharePointsLabel5: {
        id: 'radicalstart.manageSharePointsLabel5',
        defaultMessage: 'Total number of shares to transfer',
        description: 'Total number of shares to transfer'
    },
    manageSharePointsLabel6: {
        id: 'radicalstart.manageSharePointsLabel6',
        defaultMessage: 'ID sender',
        description: 'ID sender'
    },
    manageSharePointsLabel7: {
        id: 'radicalstart.manageSharePointsLabel7',
        defaultMessage: 'ID receiver',
        description: 'ID receiver'
    },
    sendLabel: {
        id: 'radicalstart.sendLabel',
        defaultMessage: 'Send',
        description: 'Send'
    },
    manageSharePointsLabel8: {
        id: 'radicalstart.manageSharePointsLabel8',
        defaultMessage: 'Minimum points for Share conversion',
        description: 'Minimum points for Share conversion'
    },
    verifyLabel: {
        id: 'radicalstart.verifyLabel',
        defaultMessage: 'Verify',
        description: 'Verify'
    },
    numberOfSharesLabel: {
        id: 'radicalstart.numberOfSharesLabel',
        defaultMessage: 'Number of Shares',
        description: 'Number of Shares'
    },
    userIdLabel: {
        id: 'radicalstart.userIdLabel',
        defaultMessage: 'UserId',
        description: 'Rider List'
    },
    accepterLabel: {
        id: 'radicalstart.accepterLabel',
        defaultMessage: 'Accepter',
        description: 'Accepter'
    },
    refuserLabel: {
        id: 'radicalstart.refuserLabel',
        defaultMessage: 'Refuser',
        description: 'Refuser'
    },
    driverCashFeeLabel: {
        id: 'radicalstart.driverCashFeeLabel',
        defaultMessage: 'Driver Commission (Cash)',
        description: 'Pricing Form'
    },
    referralFormContent1: {
        id: 'radicalstart.referralFormContent1',
        defaultMessage: `Bienvenue sur Drimona App`,
        description: 'Referral Form'
    },
    referralFormContent2: {
        id: 'radicalstart.referralFormContent2',
        defaultMessage: 'Download Drimona',
        description: 'Referral Form'
    },
    referralFormContent3: {
        id: 'radicalstart.referralFormContent3',
        defaultMessage: 'Download Drimona Driver',
        description: 'Referral Form'
    },
    referralFormContent4: {
        id: 'radicalstart.referralFormContent4',
        defaultMessage: 'Enter your Phone number',
        description: 'Referral Form'
    },
    referralCode: {
        id: 'radicalstart.referralCode',
        defaultMessage: 'Referral Code',
        description: 'Driver and Rider lists'
    },
    Support: {
        id: 'radicalstart.Support',
        defaultMessage: 'Support',
        description: 'Ban Status'
    },
    companyName: {
        id: 'radicalstart.companyName',
        defaultMessage: 'Company name, Address, and Identification number',
        description: 'Company Name'
    },
    companyNamePlaceHolder: {
        id: 'radicalstart.companyNamePlaceHolder',
        defaultMessage: 'Enter the company name, Address, and Identification number',
        description: 'Company Name'
    },
    adminEarnings: {
        id: 'radicalstart.adminEarnings',
        defaultMessage: 'Admin Earnings',
        description: 'Admin Earnings'
    },
    vatFeeLabel: {
        id: 'radicalstart.vatFeeLabel',
        defaultMessage: 'VAT fee',
        description: 'VAT fee'
    },
    adminReports: {
        id: 'radicalstart.adminReports',
        defaultMessage: 'Admin Reports',
        description: 'Admin Reports'
    },
    exportLabel: {
        id: 'radicalstart.exportLabel',
        defaultMessage: 'Export data',
        description: 'Admin Reports'
    },
    driverPrivacyPolicy: {
        id: 'radicalstart.driverPrivacyPolicy',
        defaultMessage: 'Driver Privacy Policy',
        description: 'Title',
    },
    companyName: {
        id: 'radicalstart.companyName',
        defaultMessage: 'Company name, Address, and Identification number',
        description: 'Company Name'
    },
    companyNamePlaceHolder: {
        id: 'radicalstart.companyNamePlaceHolder',
        defaultMessage: 'Enter the company name, Address, and Identification number',
        description: 'Company Name'
    },
    adminEarnings: {
        id: 'radicalstart.adminEarnings',
        defaultMessage: 'Admin Earnings',
        description: 'Admin Earnings'
    },
    vatFeeLabel: {
        id: 'radicalstart.vatFeeLabel',
        defaultMessage: 'VAT fee',
        description: 'VAT fee'
    },
    adminReports: {
        id: 'radicalstart.adminReports',
        defaultMessage: 'Admin Reports',
        description: 'Admin Reports'
    },
    exportLabel: {
        id: 'radicalstart.exportLabel',
        defaultMessage: 'Export data',
        description: 'Admin Reports'
    },
    dateRangeLabel: {
        id: 'radicalstart.dateRangeLabel',
        defaultMessage: 'Date Range',
        description: 'Admin Reports'
    },
    startDateLabel: {
        id: 'radicalstart.startDateLabel',
        defaultMessage: 'Start Date',
        description: 'Admin Reports'
    },
    endDateLabel: {
        id: 'radicalstart.endDateLabel',
        defaultMessage: 'End Date',
        description: 'Admin Reports'
    },
    adminWaitingTime: {
        id: 'radicalstart.adminWaitingTime',
        defaultMessage: 'Waiting time without fees',
        description: 'Waiting time without fees - Manage Location - field label'
    },
    cancellationTime: {
        id: 'radicalstart.cancellationTime',
        defaultMessage: 'Cancellation time',
        description: 'Cancellation time - Manage Fare - field label'
    },
    pickupArea: {
        id: 'radicalstart.pickupArea',
        defaultMessage: 'Pickup Area',
        description: 'Pickup Area - Manage Fare - field label'
    },
    exceedLimitNumber: {
        id: 'radicalstart.exceedLimitNumber',
        defaultMessage: 'Limit exceeded! You can enter up to 8 digits.',
        description: 'Limit exceeded! You can enter up to 8 digits.'
    },
    pointsForCash: {
        id: 'radicalstart.pointsForCash',
        defaultMessage: 'Points for Cash',
        description: 'Points for Cash - Manage Shares - Field Label'
    },
    exportDataIntoCSV: {
        id: 'radicalstart.exportDataIntoCSV',
        defaultMessage: 'Export Data into CSV',
        description: 'Export Data into CSV',
    },
    companyNameLabel: {
        id: 'radicalstart.companyNameLabel',
        defaultMessage: 'Name or Company name',
        description: 'Company Name'
    },
    companyAddress: {
        id: 'radicalstart.companyAddress',
        defaultMessage: 'Address',
        description: 'Company Address',
    },
    companyZipCode: {
        id: 'radicalstart.zipCode',
        defaultMessage: 'ZIP Code',
        description: 'Company Address ZIP Code',
    },
    companyRegion: {
        id: 'radicalstart.companyRegion',
        defaultMessage: 'Region',
        description: 'Company Address Region',
    },
    noOfGovtId: {
        id: 'radicalstart.noOfGovtId',
        defaultMessage: 'Number of government identification',
        description: 'Company Address - Number of government identification',
    },
    companyInfo: {
        id: 'radicalstart.companyInfo',
        defaultMessage: 'Company informations',
        description: 'Company informations',
    },
    waitingFee: {
        id: 'radicalstart.waitingFee',
        defaultMessage: 'Waiting Fee',
        description: 'WaitingFee',
    },
    invalid: {
        id: 'radicalstart.invalid',
        defaultMessage: 'Invalid',
        description: 'Invalid',
    },
    exceedCharacterLimit: {
        id: 'radicalstart.exceedCharacterLimit',
        defaultMessage: 'Limit exceeded! You can enter up to 255 characters.',
        description: 'Limit exceeded! You can enter up to 255 characters.'
    },
    reductionCoupon: {
        id: 'radicalstart.reductionCoupon',
        defaultMessage: 'Reduction coupon percentage',
        description: 'Reduction coupon percentage',
    },
    cancellationFee: {
        id: 'radicalstart.cancellationFee',
        defaultMessage: 'Cancellation Fee',
        description: 'Cancellation Fee'
    },
    cancellationPoint: {
        id: 'radicalstart.cancellationPoint',
        defaultMessage: 'Cancellation Points',
        description: 'Cancellation Points'
    },
    riderServiceFeePoints: {
        id: 'radicalstart.riderServiceFeePoints',
        defaultMessage: 'Rider Service Fee Points',
        description: 'Rider Service Fee Points'
    },
    driverServiceFeePoints: {
        id: 'radicalstart.driverServiceFeePoints',
        defaultMessage: 'Driver Service Fee Points',
        description: 'Driver Service Fee Points'
    },
    pointsLabel: {
        id: 'radicalstart.pointsLabel',
        defaultMessage: 'Points',
        description: 'Points'
    },
    cancellationServiceFee: {
        id: 'radicalstart.cancellationServiceFee',
        defaultMessage: 'Cancellation Service Fee',
        description: 'Cancellation Service Fee'
    },
    cancellationFeeLabel: {
        id: 'radicalstart.cancellationFeeLabel',
        defaultMessage: 'Cancellation Fee (with service fee)',
        description: 'Cancellation Fee (with service fee)'
    },
    omegaLabel:{
        id: 'radicalstart.omega',
        defaultMessage: 'Omega',
        description: 'Omega',
    },
    gammaLabel:{
        id: 'radicalstart.gamma',
        defaultMessage: 'Gamma',
        description: 'Gamma',
    },
    limitErrorMessage: {
        id: 'radicalstart.limitErrorMessage',
        defaultMessage: 'You can add the values between 1 to 100',
        description: 'Site Settings',
    },
    inValitInputErrorMessage: {
        id: 'radicalstart.inValitInputErrorMessage',
        defaultMessage: 'Invalid input',
        description: 'Site Settings',
    },
    ZeroValueErrorMessage: {
        id: 'radicalstart.zeroValueErrorMessage',
        defaultMessage: 'You are not allowed to enter the value 0',
        description: 'Site Settings',
    }
});

export default messages;